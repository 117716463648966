<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        What is the proper valence electron configuration for the
        <span class="text-bold"> {{ groupName }} </span>.
      </p>

      <v-radio-group v-model="inputs.input1" class="pl-14">
        <div v-for="option in options1" :key="option.value">
          <v-radio :value="option.value" />
          <stemble-latex :content="option.text" />
        </div>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question24',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking

      inputs: {input1: null},
      options1: [
        {text: '$\\mathrm{n}s^{2}$', value: '2'},
        {text: '$\\mathrm{n}s^{2}\\mathrm{n}p^{1}$', value: '3'},
        {text: '$\\mathrm{n}s^{2}\\mathrm{n}p^{6}$', value: '8'},
        {text: '$\\mathrm{n}s^{1}$', value: '1'},
        {text: '$\\mathrm{n}s^{2}\\mathrm{n}p^{2}$', value: '4'},
        {text: '$\\mathrm{n}s^{2}\\mathrm{n}p^{4}$', value: '6'},
        {text: '$\\mathrm{n}s^{2}\\mathrm{n}p^{5}$', value: '7'},
        {text: '$\\mathrm{n}s^{2}\\mathrm{n}p^{3}$', value: '5'},
      ],
    };
  },
  computed: {
    groupNumber() {
      return this.taskState.getValueBySymbol('arrayVariable').content;
    },
    groupName() {
      if (this.groupNumber.value === 1) {
        return 'alkali metals (group 1 elements)';
      } else if (this.groupNumber.value === 2) {
        return 'alkaline earth metals (group 2 elements)';
      } else if (this.groupNumber.value === 3) {
        return 'triels (group 13 elements)';
      } else if (this.groupNumber.value === 4) {
        return 'crystallogens (group 14 elements)';
      } else if (this.groupNumber.value === 5) {
        return 'pnictogens (group 15 elements)';
      } else if (this.groupNumber.value === 6) {
        return 'chalcogens (group 16 elements)';
      } else if (this.groupNumber.value === 7) {
        return 'halogens (group 17 elements)';
      } else if (this.groupNumber.value === 8) {
        return 'noble gases (group 18 elements)';
      }
    },
  },
};
</script>
<style scoped>
.v-radio {
  display: inline-block !important;
}
</style>
